/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useDataCubeState from '~/src/UserInterface/DataCube/composables/useDataCubeState'

/**
 * This middleware must be called on each page which requires a general ledger data cube
 *
 * Prerequisites for this middleware:
 * - The user must be authenticated
 *
 * This middleware check if all general ledger data cubes have been populated, to prevent a
 * user looking at incomplete data.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[GENERAL_LEDGER] Middleware fired')

  if (typeof to.params.engagementId !== 'string' || typeof to.params.phaseId !== 'string') {
    logger.error(
      '[GENERAL_LEDGER] Route does not have the correct url parameters.',
    )

    // eslint-disable-next-line ts/no-unsafe-argument
    return navigateTo(useNuxtApp().$localePath('landing'))
  }

  const nuxtApp = useNuxtApp()
  const uuidEncoder = useService('uuidEncoder')

  const phase = PhaseIri.fromId(
    uuidEncoder.decode(to.params.engagementId),
    uuidEncoder.decode(to.params.phaseId),
  )

  const { dataCubeCollection } = useDataCubeState()
  const dataCubeHaveBeenPopulated = dataCubeCollection.value.haveAllDataCubesBeenPopulatedFor(AnalysisType.GENERAL_LEDGER, phase)

  if (!dataCubeHaveBeenPopulated) {
    logger.info('[GENERAL_LEDGER] Data cubes have not been populated.')

    if (to.name?.toString().startsWith('generalLedger-Index') === true) {
      return
    }

    logger.info('[GENERAL_LEDGER] Redirecting user to the GL index page.')

    return navigateTo(
      // eslint-disable-next-line ts/no-unsafe-argument
      nuxtApp.$localePath({
        name: 'generalLedger-Index',
        params: to.params,
      }),
    )
  }

  logger.info('[GENERAL_LEDGER] Data cubes have been populated.')

  if (to.name?.toString().startsWith('generalLedger-Index') === false) {
    return
  }

  logger.info('[GENERAL_LEDGER] Redirecting user to the GL overview page.')

  return navigateTo(
    // eslint-disable-next-line ts/no-unsafe-argument
    nuxtApp.$localePath({
      name: 'generalLedger-Overview',
      params: to.params,
    }),
  )
})
